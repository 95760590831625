import React from "react";
import { HiCheck, HiX } from "react-icons/hi";
import useTranslations from "../useTranslations";
import Tooltip from "../atoms/Tooltip";
import { AiOutlineQuestionCircle } from "react-icons/all";
import { Link } from "gatsby";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Prices = () => {
  const { plans } = useTranslations();
  return (
    <div>
      <div className="flow-root pb-20 sm:pb-24">
        <div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {plans.tiers.map((tier) =>
                tier.displayOnTop ? (
                  <div
                    key={tier.id}
                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900 ring-opacity-10 sm:p-10"
                  >
                    <div>
                      <h3
                        id={tier.id}
                        className="text-base font-semibold leading-7 text-cyan-600"
                      >
                        {tier.name}
                      </h3>
                      <div className="mt-4 flex items-baseline gap-x-2">
                        {tier.prefixPriceMonthly && (
                          <span className="text-base font-semibold leading-7 text-gray-600">
                            {tier.prefixPriceMonthly}
                          </span>
                        )}
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          {tier.priceMonthly}
                        </span>
                        {tier.priceRecurrence && (
                          <span className="text-base font-semibold leading-7 text-gray-600">
                            {tier.priceRecurrence}
                          </span>
                        )}
                      </div>
                      <p className="mt-6 text-base leading-7 text-gray-600">
                        {tier.description}
                      </p>
                      <ul
                        role="list"
                        className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                      >
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <HiCheck
                              className="h-6 w-5 flex-none text-cyan-600"
                              aria-hidden="true"
                            />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Link
                      className="mt-8 block rounded-md bg-cyan-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                      to={tier.href}
                    >
                      {tier.action}
                    </Link>
                  </div>
                ) : null
              )}
              <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900 ring-opacity-10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                <div className="lg:min-w-0 lg:flex-1">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-cyan-600">
                    {plans.sandbox.title}
                  </h3>
                  <p className="mt-1 text-base leading-7 text-gray-600">
                    {plans.sandbox.description}
                  </p>
                </div>
                <a
                  href={process.env.GATSBY_APP_URL}
                  className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-cyan-600 ring-1 ring-inset ring-cyan-200 hover:ring-cyan-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                >
                  {plans.sandbox.cta} <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="mx-auto max-w-7xl px-6 pb-16 sm:pb-24 lg:px-8">
          {/* Feature comparison (up to lg) */}
          <section
            aria-labelledby="mobile-comparison-heading"
            className="lg:hidden"
          >
            <h2 id="mobile-comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="mx-auto max-w-2xl space-y-16">
              {plans.tiers.map((tier) => (
                <div key={tier.id} className="border-t border-gray-900/10">
                  <div
                    className={classNames(
                      tier.featured ? "border-cyan-600" : "border-transparent",
                      "-mt-px w-72 border-t-2 pt-10 md:w-80"
                    )}
                  >
                    <h3
                      className={classNames(
                        tier.featured ? "text-cyan-600" : "text-gray-900",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                  </div>

                  <div className="mt-10 space-y-10">
                    {plans.sections.map((section) => (
                      <div key={section.name}>
                        <h4 className="text-sm font-semibold leading-6 text-gray-900">
                          {section.name}
                        </h4>
                        <div className="relative mt-6">
                          {/* Fake card background */}
                          <div
                            aria-hidden="true"
                            className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                          />

                          <div
                            className={classNames(
                              tier.featured
                                ? "ring-2 ring-indigo-600"
                                : "ring-1 ring-gray-900/10",
                              "relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0"
                            )}
                          >
                            <dl className="divide-y divide-gray-200 text-sm leading-6">
                              {section.features.map((feature) => (
                                <div
                                  key={feature.name}
                                  className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                >
                                  <dt className="pr-4 text-gray-600">
                                    <div className="flex justify-between items-center gap-3">
                                      <Tooltip
                                        placement="right"
                                        overlay={
                                          <p style={{ maxWidth: 250 + "px" }}>
                                            <b>{feature.name}</b>
                                            <br />
                                            {feature.details}
                                          </p>
                                        }
                                      >
                                        <AiOutlineQuestionCircle className="h-4 w-4" />
                                      </Tooltip>
                                      <div>{feature.name}</div>
                                    </div>
                                  </dt>
                                  <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                    {typeof feature.tiers[tier.id] ===
                                    "string" ? (
                                      <span
                                        className={
                                          (tier.featured
                                            ? "font-semibold text-cyan-600"
                                            : "text-gray-900",
                                          "text-right")
                                        }
                                      >
                                        {feature.tiers[tier.id]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[tier.id] === true ? (
                                          <HiCheck
                                            className="mx-auto h-5 w-5 text-cyan-600"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <HiX
                                            className="mx-auto h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        )}

                                        <span className="sr-only">
                                          {feature.tiers[tier.id] === true
                                            ? "Yes"
                                            : "No"}
                                        </span>
                                      </>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>

                          {/* Fake card border */}
                          <div
                            aria-hidden="true"
                            className={classNames(
                              tier.featured
                                ? "ring-2 ring-cyan-600"
                                : "ring-1 ring-gray-900 ring-opacity-10",
                              "pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block"
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Feature comparison (lg+) */}
          <section
            aria-labelledby="comparison-heading"
            className="hidden lg:block"
          >
            <h2 id="comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="grid grid-cols-4 gap-x-8 border-t border-gray-900 border-opacity-10 before:block">
              <div aria-hidden="true" className="-mt-px"></div>
              {plans.tiers.map((tier) => (
                <div key={tier.id} aria-hidden="true" className="-mt-px">
                  <div
                    className={classNames(
                      tier.featured ? "border-cyan-600" : "border-transparent",
                      "border-t-2 pt-10"
                    )}
                  >
                    <p
                      className={classNames(
                        tier.featured ? "text-cyan-600" : "text-gray-900",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.name}
                    </p>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {tier.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="-mt-6 space-y-16">
              {plans.sections.map((section) => (
                <div key={section.name}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {section.name}
                  </h3>
                  <div className="relative -mx-8 mt-10">
                    {/* Fake card backgrounds */}
                    <div
                      className="absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      <div></div>
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                    </div>

                    <table className="relative w-full border-separate border-spacing-x-8">
                      <thead>
                        <tr className="text-left">
                          <th scope="col">
                            <span className="sr-only">Feature</span>
                          </th>
                          {plans.tiers.map((tier) => (
                            <th key={tier.id} scope="col">
                              <span className="sr-only">{tier.name} tier</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {section.features.map((feature, featureIdx) => (
                          <tr key={feature.name}>
                            <th
                              scope="row"
                              className="w-1/4 py-3 pr-1 text-left text-sm font-normal pl-8 text-gray-900"
                            >
                              <div className="flex justify-between items-center gap-5">
                                <div>{feature.name}</div>
                                <Tooltip
                                  overlay={
                                    <p style={{ maxWidth: 300 + "px" }}>
                                      <b>{feature.name}</b>
                                      <br />
                                      {feature.details}
                                    </p>
                                  }
                                >
                                  <AiOutlineQuestionCircle className="h-4 w-4" />
                                </Tooltip>
                              </div>
                              {featureIdx !== section.features.length - 1 ? (
                                <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                              ) : null}
                            </th>
                            {plans.tiers.map((tier, index) => (
                              <td
                                key={tier.id}
                                className={`relative w-1/4 pr-${
                                  index === 0 ? "4" : index === 1 ? "5" : "7"
                                } pl-${
                                  index === 0 ? "5" : index === 1 ? "3" : "1"
                                } py-0 text-center`}
                              >
                                <span className="relative h-full w-full py-3">
                                  {typeof feature.tiers[tier.id] ===
                                  "string" ? (
                                    <div
                                      className={classNames(
                                        tier.featured
                                          ? "font-semibold text-cyan-600"
                                          : "text-gray-900",
                                        "text-sm px-3 text-center"
                                      )}
                                    >
                                      {feature.tiers[tier.id]}
                                    </div>
                                  ) : (
                                    <>
                                      {feature.tiers[tier.id] === true ? (
                                        <HiCheck
                                          className="mx-auto h-5 w-5 text-cyan-600"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <HiX
                                          className="mx-auto h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}

                                      <span className="sr-only">
                                        {feature.tiers[tier.id] === true
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </>
                                  )}
                                </span>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Fake card borders */}
                    <div
                      className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      <div></div>
                      {plans.tiers.map((tier) => (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.featured
                              ? "ring-2 ring-cyan-600"
                              : "ring-1 ring-gray-900 ring-opacity-10",
                            "rounded-lg"
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Prices;
