import React from "react";
import RcTooltip from "rc-tooltip";
import PropTypes from "prop-types";

const Tooltip = ({
  overlay,
  children,
  hideTooltip,
  isInlineBlock,
  placement = "top",
}) => {
  if (!overlay || hideTooltip) {
    return children;
  }

  return (
    <div className="relative">
      <RcTooltip overlay={overlay} placement={placement}>
        <span className={isInlineBlock ? "inline-block" : ""}>{children}</span>
      </RcTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  overlay: PropTypes.any,
  children: PropTypes.node.isRequired,
  hideTooltip: PropTypes.bool,

  // (this is useful with a button to have proper size)
  isInlineBlock: PropTypes.bool,
  placement: PropTypes.string,
};

export default Tooltip;
