import React, { useState } from "react";

import CtaSection from "../components/ctaSection";
import Hero from "../components/plans/hero";
import Faq from "../components/plans/faq";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import Prices from "../components/plans/prices";

const Plans = ({ location }) => {
  const { pricing, lang } = useTranslations();
  const [currencySelected, setCurrencySelected] = useState("euros");

  return (
    <div className="bg-white">
      <HelmetHeader
        location={location}
        lang={lang}
        title={pricing.metas.title}
        metas={[{ name: "description", content: pricing.metas.description }]}
      />
      {/*<div className="bg-gradient-to-r from-teal-500 to-cyan-600 -mb-44">*/}
      <div className="bg-gradient-to-r from-teal-500 to-cyan-600 pb-96">
        <Hero
          currencySelected={currencySelected}
          setCurrencySelected={setCurrencySelected}
        />
      </div>
      <div className="-mt-80">
        <Prices />
      </div>
      <div className="pb-8 border-t-2 border-gray-300 bg-gray-50" />
      <Faq />
      <CtaSection />
    </div>
  );
};

export default Plans;
