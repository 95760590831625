import React from "react";
import reactStringReplace from "react-string-replace";
import useTranslations from "../useTranslations";

const Faq = () => {
  const { faq } = useTranslations();

  return (
    <div className="bg-gray-50 py-16 sm:py-24">
      <div className="max-w-6xl mx-auto px-8 lg:px-16">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              {faq.title}
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              {reactStringReplace(faq.subtitle, "{{customerSupport}}", () => (
                <a
                  href="mailto:support@addingwell.com"
                  className="font-medium text-cyan-600 hover:text-cyan-500"
                >
                  customer support
                </a>
              ))}
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              {faq.questions.map((item, index) => (
                <div key={index}>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    {item.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {reactStringReplace(item.answer, "<br/>", () => (
                      <br />
                    ))}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
